<template>
  <div class="p-20 rounded bg-white">
    <div class="relative">
      <div class="text-right">
        <a-space>
          <status-box :status="detail.status" type="expert">
            <span v-if="detail.status === 10">待审核</span>
            <span v-else-if="detail.status === 30">审核成功</span>
            <span v-else-if="detail.status === 99">审核失败</span>
          </status-box>
        </a-space>
      </div>
      <div v-if="updateFlag">
        <SupplyForm :dataSource="detail">
          <a-space :size="20" slot-scope="{ validate }">
            <a-button @click="update(false)">取消</a-button>
            <a-button size="large" type="primary" @click="submit(validate)">
              提交
            </a-button>
          </a-space>
        </SupplyForm>
      </div>

      <div v-else>
        <div class="p-20 border-eee bg-background rounded-lg mb-40">
          <div class="flex font-bold text-xl mb-20">
            <div style="width: calc(100% - 160px)">
              {{ requirement.name }}
            </div>
            <div class="text-right" style="color: #fc8a00; width: 160px">
              {{ requirement.budgetStr }}
            </div>
          </div>
          <div class="text-sm">
            <div class="mb-10 flex items-center">
              <div class="company-icon w-4 h-4 icon mr-1"></div>
              <div class="text-999 mr-2.5">需求方:</div>
              <div>{{ requirement.publisher }}</div>
            </div>
            <div class="mb-10 flex items-center">
              <div class="industry-icon w-4 h-4 icon mr-1"></div>
              <div class="text-999 mr-2.5">所在行业:</div>
              <div>
                <gf-tag-group>
                  <gf-tag
                    v-for="(tag, index) in requirement.industry"
                    :key="index"
                  >
                    {{ tag.Name }}
                  </gf-tag>
                </gf-tag-group>
              </div>
            </div>
            <div class="mb-10 flex items-center">
              <div class="range-icon w-4 h-4 icon mr-1"></div>
              <div class="text-999 mr-2.5">应用范围:</div>
              <div>
                <gf-tag-group>
                  <gf-tag
                    v-for="(tag, index) in requirement.scene"
                    :key="index"
                  >
                    {{ tag.Name }}
                  </gf-tag>
                </gf-tag-group>
              </div>
            </div>
            <div class="mb-10 flex items-center">
              <div class="area-icon w-4 h-4 icon mr-1"></div>
              <div class="text-999 mr-2.5">所在区域:</div>
              <div>{{ requirement.regionStr }}</div>
            </div>
          </div>
        </div>
        <SupplyDisplay :dataSource="detail" />
        <div class="text-center" v-if="detail.status > 0">
          <a-space :size="20">
            <!-- <a-button
            @click="close"
            type="danger"
            v-if="!detail.evaluate && detail.status > 0 && detail.status < 99"
            >关闭需求</a-button
          > -->
            <a-button
              @click="openRefuseModal"
              type="primary"
              ghost
              v-if="detail.status === 10"
              >审核拒绝</a-button
            >
            <a-button
              @click="passVisible = true"
              type="primary"
              v-if="detail.status === 10"
              >审核通过</a-button
            >
          </a-space>
        </div>
      </div>
    </div>

    <SelectExpertModal
      v-model="expertSelectVisible"
      @confirm="selectExpert"
    ></SelectExpertModal>
    <RefuseModal v-model="refuseVisible" @confirm="refuse"></RefuseModal>
    <RefuseModal v-model="passVisible" @confirm="pass"></RefuseModal>
  </div>
</template>

<script>
import SupplyForm from "./components/responseForm.vue";
import SupplyDisplay from "./components/responseDisplay.vue";
import SelectExpertModal from "../expert/modal.vue";
import {
  supplyExpertConfirm,
  supplyAllocateExpert,
  getExpertSupply,
  updateOp,
  putManagerComment,
  closeOp,
  publishOp,
} from "@/api/api/requirement";
export default {
  components: { SupplyForm, SelectExpertModal, SupplyDisplay },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.getExpertSupply(id);
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
  data() {
    return {
      id: "",
      detail: {},
      expertSelectVisible: false,
      refuseVisible: false,
      updateFlag: false,
      comment: {},
      passVisible: false,
      requirement: {},
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    // 修改
    update(state) {
      this.updateFlag = state;
    },
    // 打开选择专家弹窗
    openExpertModal() {
      this.expertSelectVisible = true;
    },
    // 打开审核拒绝弹窗
    openRefuseModal() {
      this.refuseVisible = true;
    },
    // 更新需求
    submit(validate) {
      validate().then((res) => {
        this.updateOp(this.id, res);
        this.getExpertSupply(this.id);
      });
    },
    // 获取需求接口
    getExpertSupply(id) {
      getExpertSupply(id)
        .then((res) => {
          this.detail = res;
          this.requirement = res.requirement;
        })
        .catch(() => {
          this.$message.error("获取详情失败");
        });
    },
    // 更新需求接口
    updateOp(id, res) {
      updateOp(id, res)
        .then(() => {
          this.$message.success("更新需求成功");
        })
        .catch(() => {
          this.$message.error("更新需求失败");
        });
    },
    // 分配专家接口
    selectExpert(data) {
      const temp = { ...data };
      supplyAllocateExpert(this.id, temp)
        .then(() => {
          this.$message.success("分配专家成功");
          putManagerComment(this.id, this.comment);
          this.expertSelectVisible = false;
          this.back();
        })
        .catch(() => {
          this.$message.error("分配专家失败");
        });
    },
    // 审核拒绝接口
    refuse(data) {
      const temp = { ...data, ok: false };

      supplyExpertConfirm(this.id, temp)
        .then(() => {
          this.$message.success("拒绝成功");
          this.refuseVisible = false;
          this.back();
        })
        .catch(() => {
          this.$message.error("拒绝失败");
        });
    },
    // 更新需求评注
    putManagerComment(id, data) {
      putManagerComment(id, data).catch(() => {
        this.$message.error("更新评注失败");
      });
    },
    // 关闭需求
    close() {
      const id = this.id,
        $message = this.$message,
        back = this.back;
      this.$confirm({
        title: "是否关闭该需求",
        okText: "关闭",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          closeOp(id)
            .then(() => {
              $message.success("关闭需求成功");
              back();
            })
            .catch(() => {
              $message.error("关闭需求失败");
            });
        },
      });
    },
    // 发布需求
    publish() {
      publishOp(this.id)
        .then(() => {
          this.$message.success("发布成功");
          this.$router.back();
        })
        .catch(() => {
          this.$message.error("发布失败");
        });
    },
    pass(data) {
      const temp = { ...data, ok: true };
      this.supplyExpertConfirm(temp);
    },
    // 审核
    supplyExpertConfirm(data) {
      supplyExpertConfirm(this.id, data)
        .then(() => {
          this.$message.success("审核通过成功");
          this.$router.back();
        })
        .catch(() => {
          this.$message.error("审核通过失败");
        });
    },
  },
};
</script>